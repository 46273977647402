<template>
  <el-card class="box-card">
    <el-form style="margin-top: 30px" ref="form" label-width="80px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="活动时间">
        <el-date-picker
            v-model="query_time"
            size="small"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
            @change="getTimeSection"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动商品" prop="">
        <el-table :data="s_list" size="small" style="width: 70%"
                  @selection-change="setSelectRows">
          <el-table-column align="center" show-overflow-tooltip type="selection"/>
          <el-table-column
              align="center"
              label="商品图片"
              prop="imgUrl"
          >
            <template #default="{row}">
              <el-image v-if="row.imgUrl" :src="row.imgUrl" class="commodity_pic"/>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="good_price"
          />
          <el-table-column
              align="center"
              label="库存"
              prop="stock"
          />
          <el-table-column
              align="center"
              label="团购价"
              prop="price"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.price" v-model="s_list[scope.$index].price"
                        placeholder="请输入内容" :value="scope.row.price"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].price" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="最低购买数量"
              prop="quantity"
          >
            <template slot-scope="scope">
              <el-input type="number" v-if="scope.row.quantity" v-model="s_list[scope.$index].quantity"
                        placeholder="请输入内容" :value="scope.row.quantity"/>
              <el-input type="number" v-else v-model="s_list[scope.$index].quantity" placeholder="请输入内容"
                        :value="0"/>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="100">
            <template #default="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button icon="el-icon-plus" type="primary" @click="showPop = true" size="small" style="margin-top: 10px">
          选择商品
        </el-button>
        <el-button v-if="s_list.length > 0" icon="el-icon-delete" size="small" type="danger" style="margin-top: 10px"
                   @click="handleDelete">批量删除
        </el-button>
        <el-button v-else icon="el-icon-delete" type="danger" size="small" style="margin-top: 10px" disabled>批量删除
        </el-button>
        <PopView :visible.sync="showPop" :chooseGoods="chooseGoods" title="选择商品" @confirm="showPopSubmit"></PopView>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>

import PopView from "@/views/components/popView";
import {apiGetBulkInfo, apiUpdateBulkActivity} from "@/request/api";
import {formatDate} from "@/utils/theTimeStamp";

export default {
  components: {PopView},
  props: {
    chooseGoods: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      s_list: [],
      showPop: false,
      query_time: [],
      selectRows: '',
      id: this.$route.query.id,
      form: {
        id: '',
        begin_time: '',
        end_time: '',
        goods: [],
      },
      rules: {},
    }
  },
  created() {
    if (this.$route.query.id) {
      this.getBulkInfo()
    }
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    getBulkInfo() {
      apiGetBulkInfo({id: this.$route.query.id}).then(res => {
        if (res.code == 200) {
          console.log(res.data)
          this.form = {
            id: res.data.id,
            begin_time: this.formatDate(res.data.begin_time),
            end_time: this.formatDate(res.data.end_time),
            goods: this.getGoods(res.data.goods),
          }
          this.query_time = [this.formatDate(res.data.begin_time), this.formatDate(res.data.end_time)]
        }
      })
    },
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd')
    },
    getGoods(arr) {
      this.list = []
      if (arr.length > 0) {
        arr.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            price: item.price,//团购价格
            quantity: item.quantity,//最低团购数量
          })
          this.s_list.push({
            sku_id: item.sku_id,
            imgUrl: item.sku_info.imgUrl,//商品图片
            name: item.sku_info.name,//商品名
            good_price: item.sku_info.price,//价格
            stock: item.sku_info.stock,//库存
            price: item.price,//团购价
            quantity: item.quantity,//最低团购数量
          })
        })
        return this.list
      }
    },
    //选择商品
    showPopSubmit(e) {
      this.showPop = true;
      console.log(e)
      if (e) {
        e.forEach(item => {
          this.s_list.push({
            sku_id: item.skuid,
            imgUrl: item.gallery,//商品图片
            name: item.name,//商品名
            good_price: item.price,//价格
            stock: item.stock,//库存
            price: item.price,//团购价
            quantity: 1,//最低团购数量
          })
        })
        const smap = new Map()
        // 对id属性进行去重
        this.s_list = this.s_list.filter(key => !smap.has(key.sku_id) && smap.set(key.sku_id, 1))
        this.listLoading = false
      }
    },
    //删除选中商品
    handleDelete(row) {
      if (row.sku_id) {
        this.$confirm('你确定要删除当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.s_list.map((val, i) => {
            if (val.sku_id == row.sku_id) {
              this.s_list.splice(i, 1)
            }
          })
        })
      } else {
        this.$confirm('你确定要删除所有选中项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (var j = 0; j < this.selectRows.length; j++) {
            this.s_list.map((val, i) => {
              if (val.sku_id == this.selectRows[j].sku_id) {
                this.s_list.splice(i, 1)
              }
            })
          }
        })
      }
    },
    //获取时间段
    getTimeSection(event) {
      if (event != null) {
        this.form.begin_time = event[0]
        this.form.end_time = event[1]
      } else {
        this.form.begin_time = ''
        this.form.end_time = ''
      }
    },
    //清空表单
    close() {
      // console.log(222222)
      this.query_time = []
      this.s_list = []
      // this.$refs['form'].resetFields()
      // this.form = this.$options.data().form
    },
    async submit() {
      this.list = []
      if (this.s_list.length > 0) {
        this.s_list.forEach(item => {
          this.list.push({
            sku_id: item.sku_id,
            price: item.price,//团购价格
            quantity: item.quantity,//最低团购数量
          })
        })
      } else {
        this.$message.error('参与活动商品不能为空')
        return
      }
      const map = new Map()
      this.form.goods = this.list.filter(key => !map.has(key.sku_id) && map.set(key.sku_id, 1))
      apiUpdateBulkActivity(this.form).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.close()
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}

</script>
